export const LOCAL_STORAGE_KEYS = {
  TOKEN: "TOKEN",
  USER: "USER",
  ROLE: "Role",
  HEI: "HEI",
  USERDATA: "USERDATA",
};

export const ROLES = {
  HEI: "HEI",
  ADMIN: "ADMIN",
  AIC: "AIC",
  OFFICE_ASSITANT: "OFFICE_ASSITANT",
  DEPUTY_DIRECTOR: "Deputy Director",
  CHAIR_MAN: "Chair_Man",
};
export const Roles = {
  HEI: "Hei",
  ADMIN: "Admin",
  AIC: "Aic",
};
export const USERTYPE = {
  Admin: "Admin",
  Hei: "HEI",
  Aic: "AIC",
};

export const INT_ROLES = {
  HEI: 2,
  ADMIN: 1,
  AIC: 3,
};
export const StringRoles = {
  ADMIN: "1",
  OFFICE_ASSISTANT: "6",
  DEPUTY_DIRECTOR: "7",
  CHAIR_MAN: "8",
  AIC: "2",
  HEI: "3",
  PROGRAMEINCHARGE: "4",
  PROGRACOORDINATOR: "5",
};
export const VIEWS = {
  AIC: "AIC",
  AIC_INVITATION: "AIC_INVITATION",
};
export const HEIVIEWS = {
  Student: "Student",
  Faculty: "Faculty",
};
export const FORM_IDS = {
  SAR_FORM: 1,
  EVALUATION_FORM: 2,
  FEEDBACK_FORM: 3,
};

export const RUBRICS_PRIFIX = {
  COLUMN: "column_",
  ROW: "row_",
};

export const QUESTION_TYPES = {
  TEXT: 1,
  TEXTAREA: 2,
  CHECKBOX: 3,
  RADIO: 4,
  TABLE: 5,
  ATTACHMENT: 6,
  RUBRICS: 7,
  RATING: 8,
  EVALUATION_FORM: 9,
};

export const ADMIN_TIMLINE = {
  SUBMITTED_BY: "submitted_by",
  NAME_OF_HEI: "name_of_hei",
  APPLICATION_ACCEPTED: "application_accepted",
  SAR: "SAR",
  ASSIGNED_AICS: "Assigned_AICs",
  VISIT_PLANNED: "visit_planned",
  ACCEPTED_BY: "application_accepted",
  RECOMENDATION: "app_recommendation",
  SIGNATURE: "signature",
  Assigned_AICs: "assigned_AICs",
  Batch: "programBatch",
  Observer: "observer_name",
  StartDate: "date_from",
  EndDate: "date_to",
};

export const ADMIN_CONFIGS = {
  SHOW_ACCEPT_SECTION: "showAcceptSection",
  SHOW_ASSIGN_AIC_SECTION: "showAssignAicSection",
  SHOW_VISIT_PLANNED_SECTION: "showVisitPlannedSection",
  SHOW_SCHEDULE_VISIT_SECTION: "showScheduleVisitSection",
  SHOW_VISIT_COMPLETE_SECTION: "showVisitCompleteSection",
  SHOW_RECOMMENDATION_SECTION: "showRecommendationSection",
  SHOW_SIGNATURE_SECTION: "showSignatureSection",
  SHOW_DEPUTY_ACCEPT_SECTION: "showDeputyAcceptSection",
};
export const AIC_TIMELINE = {
  SUBMITTED_BY: "submitted_by",
  NAME_OF_HEI: "name_of_hei",
  APPLICATION_ACCEPTED: "application_accepted",
  SAR: "SAR",
  ASSIGNED_AICS: "Assigned_AICs",
  VISIT_PLANNED: "visit_planned",
  RECOMENDATION: "application_recommendation",
  SIGNATURE: "signature",
};
export const AIC_CONFIGS = {
  SHOW_ACCEPT_SECTION: "showAcceptSection",
  SHOW_ASSIGN_AIC_SECTION: "showAssignAicSection",
  SHOW_VISIT_PLANNED_SECTION: "showVisitPlannedSection",
  SHOW_SCHEDULE_VISIT_SECTION: "showScheduleVisitSection",
  SHOW_VISIT_COMPLETE_SECTION: "showVisitCompleteSection",
  SHOW_SAR_SUBMITTED_BY: "Sar_Submitted",
  SHOW_RECOMMENDATION_SECTION: "application_recommendation",
  SHOW_SIGNATURE_SECTION: "showSignatureSection",
};
export const PERMISSION_CONFIGS = {
  Dashboard: "Dashboard",
  Application: "Application",
  "Visit Type": "Visit Type",
  Users: "Users",
  Hei: "Hei",
  Criterion: "Criterion",
  Aic: "Aic",
  "Setting:Application-permission": "Setting:Application-permission",
  Setting: "Setting",
  "Setting:programs": "Setting:programs",
  "Setting:rating": "Setting:rating",
  "Setting:criterion weightage": "Setting:criterion weightage",
  "Setting:Permission": "Setting:Permission",
};

export const WAINTING_ON_ENTITY = {
  HEI: "HEI",
  NTC: "NTC",
  AIC: "AIC",
  NAN: "NA",
};
export const AIC_ROLE = {
  Expert: {
    tile: "Expert",
    id: 2,
  },
  CONVENER: {
    tile: "Convener",
    id: 1,
  },
  BOTH: {
    title: "Both",
    id: 3,
  },
};
export const APPLICATION_STATE = {
  DRAFT: 1,
  PENDING: 2,
  Acknowledged: 3,
  Rejected_with_comments: 4,
  Terminated: 5,
  Accepted: 6,
  Inprogress: 7,
  Inprogress_with_comments: 8,
  Evaluated: 9,
  Evaluation_acknowledged: 10,
  Evaluation_with_comments: 11,
  Evaluation_accepted: 12,
  Completed: 13,
  Submitted_to_convener: 14,
  Termination_in_progress: 15,
};

export const PERIOD_TYPE = {
  YEARLY: "Yearly",
  MONTHLY: "Monthly",
  WEEKLY: "Weekly",
};

export const INTERIM_STATEMENT =
  "During your last evaluation, your performance regarding this question was deemed unsatisfactory. What actions have you taken to address and improve this since then?";

export const VISIT_TYPES = {
  ZERO: 1,
  INTERIM: 2,
  ACCREDIATION: 3,
  OTHER: 4,
  CHANGE_OF_SCOPE: 5,
  CONFIRMATORY_VISIT: 6,
};

export const VISIT_TYPES_BY_ID = {
  1: "Zero Visit",
  2: "Interim Visit",
  3: "Accrediation Visit",
  4: "Other Visit",
  5: "Change of Scope",
  6: "Confirmatory Visit",
};

export const PERMISSIONS = {
  DASHBOARD: 1,
  APPLICATION: 2,
  VISIT_TYPE: 3,
  USERS: 4,
  HEI: 5,
  CRITERION: 6,
  AIC: 7,
  SETTING_APPLICATION_PERMISSION: 8,
  SETTING_PROGRAMS: 9,
  SETTING_RATING: 10,
};

// Example usage
